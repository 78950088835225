import service from '@/utils/request.js'
// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/****************************************************************
 * 部门管理
 ***************************************************************/


/**
 * 仓库列表查询
 */
export function get_index(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/department/index',
      method: 'post',
      data
    })
}

/**
 * 编辑仓库
 */
export function get_edit(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/department/edit',
      method: 'post',
      data
    })
}



/**
 * 删除仓库
 */
export function get_del(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/department/del',
      method: 'post',
      data
    })
}



/**
 * 添加仓库
 */
export function get_add(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/department/add',
      method: 'post',
      data
    })
}

